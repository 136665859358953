<template>
  <div v-if="editor" class="rounded-xl border p-4">
    <template v-if="route.name === 'debate' || route.name === 'paper_debate'">
      <div class="list-label opacity-50 mb-5">
        {{ $t('EditorDebate') }}
      </div>

      <div v-if="editor.length <= 2" class="flex">
        <CommonEditorCardInfo
          v-for="(anEditor, index) in editor"
          :editor="anEditor"
          :key="index"
          class="flex-1"
        />
      </div>

      <CommonDivider class="-mx-4 px-4" />
      <div class="list-title-xs">
        {{ $t('DebateParticipate') }}
      </div>
      <div
        class="list-title-xxxs whitespace-pre-line"
        v-html="$t('DebateFormat')"
      />
      <div class="body-xs opacity-50 mt-2 mb-4">
        {{ $t('DebateDisclaimer') }}
      </div>
      <a :href="'mailto:' + siteConfig.debateEmail">
        <CommonButton bg="bg-blue" text="text-white" size="medium">
          {{ $t('DebateSend') }}
        </CommonButton>
      </a>
    </template>
    <template
      v-else-if="
        route.name === 'names-index' || route.name === 'paper_names-index'
      "
    >
      <div class="list-label opacity-50 mb-5">
        {{ $t('EditorNames') }}
      </div>
      <CommonImageLoader
        class="rounded-full bg-black bg-opacity-10 w-15 h-15 overflow-hidden bg-cover bg-center"
        :src="'/A_Logomark.svg'"
        alt="Altinget logo"
        :width="15"
        :height="15"
      />
      <div class="list-label opacity-50 flex items-center mt-3">
        <img
          src="~/assets/icons/mail.svg?url"
          class="w-4 h-4 opacity-35 inline-block mr-1"
          :alt="$t('Email')"
        /><a :href="'mailto:' + config.public.site.namesEmail">{{
          config.public.site.namesEmail
        }}</a>
      </div>
    </template>
    <template
      v-else-if="
        false &&
        (route.name === 'job-names' || route.name === 'paper_job-names')
      "
    >
      <div v-if="editor.length <= 2" class="flex">
        <CommonEditorCardInfo
          v-for="(anEditor, index) in editor"
          :editor="anEditor"
          :key="index"
          class="flex-1"
        />
      </div>
      <CommonDivider class="-mx-4 px-4" />
      <div class="list-title-xs">
        {{ $t('Advertise') }}
      </div>
      <div
        class="list-title-xxxs whitespace-pre-line"
        v-html="$t('AdvertiseProducts')"
      />
      <div class="body-xs opacity-50 mt-2 mb-4">
        {{ $t('AdvertiseContact') }}
      </div>
      <NuxtLink :to="{ name: 'ads' }">
        <CommonButton size="medium">
          {{ $t('AdvertiseMore') }}
        </CommonButton>
      </NuxtLink>
    </template>
    <template v-else>
      <div class="list-label opacity-50 mb-5">
        {{
          store.currentPaper && store.currentPaper?.RecordId !== 1
            ? $t('EditorFor', { paper: store.currentPaper.Name })
            : $t('EditorFrontpage')
        }}:
      </div>

      <div v-if="editor.length <= 2" class="flex">
        <CommonEditorCardInfo
          v-for="(anEditor, index) in editor"
          :editor="anEditor"
          :key="index"
          class="flex-1"
        />
      </div>
      <NuxtLink
        v-if="aboutArticleId"
        :to="{
          name: 'article',
          params: {
            id: aboutArticleId,
          },
        }"
        class="mt-2 border whitespace-nowrap cursor-pointer inline-flex items-center transition-colors duration-100 select-none shrink-0 justify-center h-[30px] border-gray-300 bg-white hover:bg-gray-300 text-gray-700 hover:text-gray-900 button-m px-3 rounded-full"
      >
        {{ $t('AboutPortal') }}
      </NuxtLink>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Editor } from '~/typesManual/content_api/paper'
import type { WriterFull } from '~/typesManual/content_api/article'

const nuxtApp = useNuxtApp()
const route = useRoute()
const config = useRuntimeConfig()
const siteConfig = useSiteConfig()

const store = useIndexStore()

const props = defineProps<{
  id?: string
}>()

const editor = ref<Editor[] | WriterFull[] | null>()
const aboutArticleId = computed(() => {
  if (store.currentPaper && store.currentPaper.AboutPortal) {
    const oldString = store.currentPaper.AboutPortal
    return oldString.substring(oldString.lastIndexOf('/') + 1)
  } else {
    return ''
  }
})

if (props.id) {
  editor.value = [await nuxtApp.$api.content.writerFull(props.id)]
} else {
  editor.value = store.currentPaper?.Editor
}
</script>
